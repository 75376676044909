import { useEffect, useState } from "react";
import { regGet } from "../../api-requests/apiCallHelper";
import logo from "../Units/InfoSheetComponents/logo.png";
import { useHistory, useParams } from "react-router-dom";
import { Constants } from "../../api-requests/apiLinkConstants";
import {
  BookingPortalInfoResponse,
  BookingPortalResponse,
  CleaningPortalInfoResponse,
  ParkingInformationResponse,
  UnitPortalInfoResponse,
} from "../../utils/Types";
import { Divider } from "@mui/material";
import dayjs from "../../utils/Dayjs";
import AtmosphereParking from "./ParkingPDFs/AtmosphereParking";
import Header from "./ParkingPDFs/Header";
import Logo from "../../client-facing/img/SKYVIEWSUITESDark.png";
import CollegeParkParking from "./ParkingPDFs/CollegeParkParking";
import ElementParking from "./ParkingPDFs/ElementParking";
import IconParking from "./ParkingPDFs/IconParking";
import MLSParking from "./ParkingPDFs/MLSParking";
import OpusParking from "./ParkingPDFs/OpusParking";
import QwestParking from "./ParkingPDFs/QwestParking";
import StudioParking from "./ParkingPDFs/StudioParking";
import UniversityPlaza from "./ParkingPDFs/UniversityPlaza";
import Waterclub from "./ParkingPDFs/Waterclub";
import GerrardParking from "./ParkingPDFs/GerrardParking";
import IceParking from "./ParkingPDFs/IceParking";

const BookingPortal = (props: any) => {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [bookingInfo, setBookingInfo] = useState<BookingPortalInfoResponse>();
  const [unit, setUnit] = useState<UnitPortalInfoResponse>();
  const [parkingInfo, setParkingInfo] = useState<ParkingInformationResponse>();
  const [cleaning, setCleaning] = useState<CleaningPortalInfoResponse[]>();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const ParkingComponentLoad = new Map([
    ["Atmosphere", AtmosphereParking()],
    ["College Park", CollegeParkParking()],
    ["Element", ElementParking()],
    ["Ice Condos", IceParking()],
    ["Icon", IconParking()], //note: bug only issued for the above three csvs, the active and inactive users sharing same csv wasn't placed as a bug but should let MK know
    ["Gerrard", GerrardParking()],
    ["MLS", MLSParking()],
    ["Opus", OpusParking()],
    ["Qwest", QwestParking()],
    ["Studio", StudioParking()],
    ["University Plaza", UniversityPlaza()],
    ["Waterclub", Waterclub()],
  ]);

  const toggleMenu = () => {
    console.log("Menu Toggled");
    setIsMenuOpen(!isMenuOpen);
  };

  const loadData = async () => {
    let signature = new URLSearchParams(window.location.search).get(
      "signature"
    );
    let bookingInformation: BookingPortalResponse = await regGet(
      `${Constants.API_PATH_GET_BOOKING_PORTAL_INFO}${bookingId}?signature=${signature}`
    );
    setBookingInfo(bookingInformation.data.bookingData);
    setCleaning(bookingInformation.data.cleaning);
    setUnit(bookingInformation.data.unitInfo);
    setParkingInfo(bookingInformation.data?.parkingInformation);
  };
  useEffect(() => {
    console.log("Loading Data for booking portal: ");
    loadData();
  }, []);

  const loadParking = () => {
    if (parkingInfo) {
      return getIfContainsKey(
        ParkingComponentLoad,
        parkingInfo.ParkingInfoBuilding.building_name
      );
    } else {
      return null;
    }
  };

  const getIfContainsKey = (map: any, str: string) => {
    const lowerstr = str.toLowerCase();
    for (let key of map.keys()) {
      const lowerKey = key.toLowerCase();

      if (lowerstr.includes(lowerKey)) {
        return map.get(key);
      }
    }
  };

  return (
    <>
      <div className="bg-white">
        <div className="px-4 md:px-8 lg:px-24 xl:px-56">
          <div className="flex justify-between pt-5">
            <div className="justify-start">
              <img className="h-3" src={logo} alt="" />
              <h1 className="h1">
                {unit?.suite_name} (Unit #: {unit?.suite_number}),{" "}
                {unit?.building.address}
              </h1>
              <h2 className="h2 font-bold text-2xl">
                Booking ID {bookingInfo?.id}
              </h2>
            </div>
          </div>
          <div className="line" />
        </div>
        <div className="space-y-5 px-4 md:px-8 lg:px-24 xl:px-56">
          <div className="space-y-3 mt-4">
            <h1 className="h1">Booking Summary</h1>
            <h3 className="h3">
              Tenant:{" "}
              <span className="h2">
                {bookingInfo?.tenant?.first_name}{" "}
                {bookingInfo?.tenant.last_name}
              </span>
            </h3>
            <h3 className="h3">Guest Office Wifi:</h3>
            <p>SVS Office Guest</p>
            <p>PW: skyviewsuites</p>
            <p>
              For guests without cell service, the{" "}
              <strong>Guest Office Wifi</strong> can be used to connect with our
              team for any key access issues. This ensures that all guests can
              reach out for assistance promptly.
            </p>
            <p>
              Suite WiFi information is available under the Unit Info Sheet
              section below.
            </p>
            <Divider />
            <h3 className="h3 text-2xl">Check-In Date/Time: </h3>
            <p className="p text-2xl underline decoration-green-500">
              {dayjs(bookingInfo?.check_in).format("LL")} at 4:00 pm
            </p>
            <h3 className="h3 text-2xl">Check-Out Date/Time: </h3>
            <p className="p text-2xl underline decoration-green-500">
              {dayjs(bookingInfo?.check_out).format("LL")} at 11:00 am
            </p>
            <h3 className="h3 text-2xl">
              Key Access Code:{" "}
              <span className="underline decoration-green-500 decoration-double">
                {bookingInfo?.keyCode}
              </span>
            </h3>
            <p className="text-2xl">
              <strong>Key Pick Up Location: </strong>
              <span className="">
                142 Simcoe Street, Toronto, ON, M5H 3G5, storefront office
              </span>
            </p>
            <Divider />
          </div>
          <div className="space-y-3 mt-4">
            <h1 className="h1">Key PickUp</h1>
            <div className="h-full my-5">
              <iframe
                src="https://www.youtube.com/embed/onnsNw6bhFs?si=TXAILMFhc9qj0efY"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className="w-full md:h-540 h-1/2"
                allowFullScreen={true}
              ></iframe>
            </div>
            <br />
            <strong className="mt-10">
              Instructions in Retrieving your Keys:{" "}
            </strong>
            <ol className="list-decimal ml-10">
              <li>
                Open the shutter to the left of our storefront office door
                located at 142 Simcoe Street.
              </li>
              <li>Tab on the Smartbox Screen.</li>
              <li>
                Select 'Key Pickup' and enter your key access code provided
                below.
              </li>
              <li>A box will open revealing your keys.</li>
            </ol>
            <p className="p">
              This box will only open from 4pm onwards on the day of your
              arrival.
            </p>
            <Divider />
          </div>
          <div className="space-y-3 mt-2">
            <h1 className="h1">Check-Out Instructions</h1>
            <p>
              Your check-out is schedule for{" "}
              {dayjs(bookingInfo?.check_out).format("LL")} at{" "}
              <strong>11 am.</strong>
            </p>
            <ul className="list-disc ml-10">
              <li>
                Leave all keys, fobs, and parking remotes on the kitchen counter
              </li>
              <li>
                Close the front door and leave it unlocked; our cleaning team
                will arrive at 11 am on {bookingInfo?.check_out}.
              </li>
            </ul>
            <p className="p">
              For those who require their parking remote to leave the building;
              please leave it in the black mail box at 142 Simcoe Street.
            </p>
          </div>
        </div>
        <div className="px-4 md:px-8 lg:px-24 xl:px-56">
          <div className="line" />
          <div className="flex justify-between pt-5 mb-5">
            <div className="justify-start">
              <h1 className="h1">Unit Info Sheet</h1>
              <h2 className="h2">
                {unit?.building.address}, {unit?.building.building_name}
              </h2>
              <h2 className="h2">
                {unit?.suite_name}, Unit #{unit?.suite_number}
              </h2>
              <h2 className="h2">Suite Type: {unit?.suite_type}</h2>
            </div>
          </div>
          <Divider />
        </div>
        <div className="space-y-5 px-4 md:px-8 lg:px-24 xl:px-56 mt-3">
          {parkingInfo?.ParkingInfo && (
            <div>
              <div className="flex flex-col">
                <h3 className="h3">Parking</h3>
                <div className="flex flex-row my-3">
                  <h3 className="h3">Your Parking Spot: </h3>
                  <div className="w-1/2">
                    <div className="ml-3 uppercase -mt-0.5">
                      <strong className="p text-green-500 underline decoration-green-500 text-2xl">
                        {
                          parkingInfo?.ParkingInfo?.parking_spot
                            .parking_spot_level
                        }
                        , {parkingInfo?.ParkingInfoBuilding.address}
                      </strong>
                    </div>
                  </div>
                </div>
                <Divider />
              </div>
            </div>
          )}
          <div className="space-y-3">
            <h3 className="h3">Amenities</h3>
            <p className="p">{unit?.building.amenities}</p>
            <Divider />
          </div>
          <div className="space-y-3">
            <h3 className="h3">Special Instructions</h3>
            <p className="p">{unit?.building.special_instructions}</p>
            <Divider />
          </div>
          <div className="flex flex-col justify-between">
            <div className="space-y-3">
              <h3 className="h3">Internet</h3>
              <div>
                <p className="p text-xl">Wireless Network Name: SVS</p>
                <p className="p text-xl">Password: skyviewsuites</p>
              </div>
            </div>
          </div>
          <Divider className="my-5" />
        </div>
        <div className="px-4 md:px-8 lg:px-24 xl:px-56">
          {parkingInfo?.ParkingInfo && (
            <>
              <div className="flex justify-between pt-5 mb-5">
                <div className="justify-start">
                  <h1 className="h1">Parking Info Sheet</h1>
                </div>
                <div className="justify-end">
                  <img className="h-6 mt-4 hidden md:block" src={logo} alt="" />
                </div>
              </div>
              <div className="line mb-5" />
              {loadParking()}
              <Divider />
            </>
          )}
          <div className="space-y-3 mt-5">
            <div>
              <h3 className="h3">Need Help?</h3>
            </div>
            <div>
              <b className="p">
                Our help desk is open Monday - Saturday 9am to 2am
              </b>
              <p className="p">Any Questions: www.help.skyviewsuites.com</p>
              <p className="p">
                Can't find what you are looking for? Submit a request by
                clicking Submit a request in the top right!{" "}
              </p>
              <p className="p pb-3">
                Internet/TV/Phone Questions:{" "}
                <b>Frontline: (647) 793-8570 Rogers: 1 (888) 764-3771</b>
              </p>
              <Divider />
            </div>
          </div>
          <div className="space-y-3 pb-6">
            <h3 className="h3">1 (888) 787-7829</h3>
            <h3 className="h3">www.help.skyviewsuites.com</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingPortal;
