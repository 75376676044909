import { Component } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { FiMoreHorizontal, FiSettings } from "react-icons/fi";
import { TiThMenu } from "react-icons/ti";
import { AiOutlineDashboard } from "react-icons/ai";
import { BiBuildingHouse, BiBugAlt } from "react-icons/bi";
import {
  RiContactsBookLine,
  RiDashboardLine,
  RiFilePaper2Line,
  RiContactsBookFill,
  RiBillLine,
} from "react-icons/ri";
import { GoSignIn, GoCalendar } from "react-icons/go";
import { MdContacts } from "react-icons/md";
import { HiAtSymbol } from "react-icons/hi";
import { MdExtension, MdLocalParking } from "react-icons/md";
import { LogOut } from "../logout/LogoutButton";
import { GetAppStage } from "../api-requests/api-gateway";
import {
  bookingsPermissionsView,
  checkInsPermissionsView,
  cleaningCalendarPermissionsView,
  contactsPermissionsView,
  extensionsPermissionsView,
  invoicesPermissionsView,
  parkingsPermissionsView,
  reportingPermissionsView,
  unitsPermissionsView,
} from "../utils/PermissionViewForDashboard";

type Props = {
  openSettings: any;
  openBug: any;
  userData: any;
};

type State = {
  navOpen: boolean;
};

const Routing = (props: any) => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path, url } = useRouteMatch();

  let NavLinks = [
    <Link key={0} to={`${url}/dashboard`} className={props.navItems}>
      <RiDashboardLine className="text-blue-100 group-hover:text-white text-xl  lg:text-2xl self-center mb-1" />
      <span className={props.navText}>Dashboard</span>
    </Link>,
  ];

  // Initialize a Set to keep track of the unique keys added
  const uniqueKeys = new Set();
  console.log(props.userType);

  for (const userType of props.userType) {
    if (
      cleaningCalendarPermissionsView.includes(userType) &&
      !uniqueKeys.has("cleaning-calendar")
    ) {
      uniqueKeys.add("cleaning-calendar");
      NavLinks.push(
        <Link
          key={1}
          to={`${url}/dashboard/cleaning-calendar`}
          className={props.navItems}
        >
          <GoCalendar className="mt-3 text-blue-100 group-hover:text-white text-xl  lg:text-2xl self-center mb-1" />
          <span className={props.navText}>Calendar</span>
        </Link>
      );
    }

    if (
      checkInsPermissionsView.includes(userType) &&
      !uniqueKeys.has("check-ins")
    ) {
      uniqueKeys.add("check-ins");
      NavLinks.push(
        <Link key={2} to={`${url}/check ins`} className={props.navItems}>
          <GoSignIn className="mt-3 text-blue-100 group-hover:text-white text-xl  lg:text-2xl self-center mb-1" />
          <span className={props.navText}>Check Ins</span>
        </Link>
      );
    }

    if (
      bookingsPermissionsView.includes(userType) &&
      !uniqueKeys.has("bookings")
    ) {
      uniqueKeys.add("bookings");
      NavLinks.push(
        <Link key={3} to={`${url}/bookings`} className={props.navItems}>
          <RiContactsBookLine className="mt-3 text-blue-100 group-hover:text-white text-xl  lg:text-2xl self-center mb-1" />
          <span className={props.navText}>Bookings</span>
        </Link>
      );
    }

    if (
      parkingsPermissionsView.includes(userType) &&
      !uniqueKeys.has("parkings")
    ) {
      uniqueKeys.add("parkings");
      NavLinks.push(
        <Link key={5} to={`${url}/parkings`} className={props.navItems}>
          <MdLocalParking className="mt-3 text-blue-100 group-hover:text-white text-xl  lg:text-2xl self-center mb-1" />
          <span className={props.navText}>Parkings</span>
        </Link>
      );
    }

    if (
      invoicesPermissionsView.includes(userType) &&
      !uniqueKeys.has("invoices")
    ) {
      uniqueKeys.add("invoices");
      NavLinks.push(
        <Link
          key={6}
          to={`${url}/invoices`}
          className={`${props.navItems} flex flex-col items-center`}
        >
          <div className="icon-container">
            <RiFilePaper2Line
              className="text-blue-100 group-hover:text-white mt-5 mb-1 lg:text-2xl self-center"
              style={{ width: "24px", height: "24px" }} // Set specific size
            />
          </div>
          <span className={`${props.navText} text-center mb-2`}>
            Invoices <br /> (Legacy)
          </span>
        </Link>
      );
    }

    if (unitsPermissionsView.includes(userType) && !uniqueKeys.has("units")) {
      uniqueKeys.add("units");
      NavLinks.push(
        <Link key={8} to={`${url}/units`} className={props.navItems}>
          <BiBuildingHouse className="mt-3 text-blue-100 group-hover:text-white text-xl  lg:text-2xl self-center mb-1" />
          <span className={props.navText}>Units</span>
        </Link>
      );
    }

    if (
      contactsPermissionsView.includes(userType) &&
      !uniqueKeys.has("contacts")
    ) {
      uniqueKeys.add("contacts");
      NavLinks.push(
        <Link key={10} to={`${url}/contacts`} className={props.navItems}>
          <MdContacts className="mt-2 text-blue-100 group-hover:text-white text-xl lg:text-2xl self-center mb-1" />
          <span className={props.navText}>Contacts</span>
        </Link>
      );
    }

    if (
      reportingPermissionsView.includes(userType) &&
      !uniqueKeys.has("reporting")
    ) {
      uniqueKeys.add("reporting");
      NavLinks.push(
        <Link key={9} to={`${url}/reporting`} className={props.navItems}>
          <AiOutlineDashboard className="mt-1 text-blue-100 group-hover:text-white text-xl lg:text-2xl self-center mb-1" />
          <span className={props.navText}>Reporting</span>
        </Link>
      );
    }

    if (
      extensionsPermissionsView.includes(userType) &&
      !uniqueKeys.has("extensions")
    ) {
      uniqueKeys.add("extensions");
      NavLinks.push(
        <Link key={11} to={`${url}/extensions`} className={props.navItems}>
          <MdExtension className="mt-2 text-blue-100 group-hover:text-white text-xl lg:text-2xl self-center mb-1" />
          <span className={props.navText}>Extensions</span>
        </Link>
      );
    }
  }

  NavLinks.sort((a: any, b: any) => a.key - b.key);
  return (
    <div className="flex lg:flex-col flex-row items-start w-full overflow-y-auto no-scrollbar">
      {NavLinks}
    </div>
  );
};

class NavBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      navOpen: false,
    };
  }

  handleNavToggle = () => {
    let navBar = document.getElementById("navBar");
    let content = document.getElementById("contentCon");

    if (this.state.navOpen === false) {
      this.setState({ navOpen: true });
      navBar!.classList.add("w-56");
      content!.classList.add("lg:pl-56");
      navBar!.classList.remove("w-24");
      content!.classList.remove("lg:pl-24");
    } else {
      this.setState({ navOpen: false });
      navBar!.classList.add("w-24");
      content!.classList.add("lg:pl-24");
      navBar!.classList.remove("w-56");
      content!.classList.remove("lg:pl-56");
    }
  };

  render() {
    let navToggleButton;
    let navItems;
    let navText;
    let navSettings;
    let navProfile;

    if (this.state.navOpen === true) {
      navToggleButton = (
        <img
          alt="logo"
          src={GetAppStage().logoPath}
          className=" fade-in transition duration-200 mx-auto w-3/4 py-4"
        />
      );
      navItems =
        "slide-in-blurred-top group h-16 w-full mt-1 flex flex-row  align-start justify-start pl-8  content-center";
      navText =
        "font-display text-blue-100 pb-1 group-hover:text-white text-xs lg:text-sm self-center pl-2 ";

      navSettings = (
        <div>
          <button
            onClick={this.props.openSettings}
            className=" fade-in mb-4 mt-4 mr-2 transition duration-100  hover:opacity-80 "
          >
            <FiSettings className="text-white text-xl mx-auto " />
          </button>
        </div>
      );

      navProfile = (
        <div className="transition-all duration-300 h-10 w-full pl-5 mt-6 rounded-full mb-5 flex flex-row">
          <img
            alt="profile-pic"
            src={this.props.userData.pfp}
            className=" opacity-90 hover:opacity-100 border-2 border-solid h-10 w-10 object-center object-cover rounded-full shadow-lg "
          />
          <div className=" slide-in-blurred-top duration-500 flex flex-col justify-center ">
            <span className="font-display text-white pl-3  font-bold leading-normal  text-xs truncate ">
              {this.props.userData.user_f_name}{" "}
              {this.props.userData.user_l_name}{" "}
            </span>
            <span className="font-display text-white pl-3 font-thin   text-xs ">
              {this.props.userData.user_position}
            </span>
          </div>
        </div>
      );
    } else {
      navToggleButton = (
        <TiThMenu className="fade-in  transition duration-200 text-white text-xl mx-auto group-hover:text-green-100" />
      );
      navItems =
        " group h-16 w-full align-center justify-center flex flex-col ";
      navText =
        "font-display text-blue-100 pb-1 group-hover:text-white text-xs self-center";

      navSettings = (
        <button
          onClick={this.props.openSettings}
          className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
        >
          <FiSettings className="text-white text-xl mx-auto " />
        </button>
      );

      navProfile = (
        <div className=" fade-in h-10 w-10 mt-6 rounded-full mb-5  ">
          <img
            alt="profile-pic"
            src={this.props.userData.pfp}
            className=" opacity-90 hover:opacity-100 border-2 border-solid h-10 w-10 object-center object-cover rounded-full shadow-lg "
          />
        </div>
      );
    }

    return (
      <div>
        <div
          id="navBar"
          className={
            "transition-all hidden  duration-300 fixed w-24 h-screen left-0 lg:flex flex-col items-center shadow-inner shadow-lg " +
            GetAppStage().bg_main
          }
        >
          <button
            onClick={this.handleNavToggle}
            className={
              " group transition duration-200 w-full shadow-md  h-20 mx-auto " +
              GetAppStage().bg_alt
            }
          >
            {navToggleButton}
          </button>

          {navProfile}

          <Routing
            navItems={navItems}
            navText={navText}
            userType={this.props.userData.UserType}
          />

          <div className="mt-auto">{navSettings}</div>
        </div>

        {/* ------ Mobile Nav ------- */}
        <div>
          <div className="transition-all lg:hidden z-40 duration-300 fixed bg-gray-100 h-12 w-screen top-0 left-0 flex flex-row items-center justify-between shadow-xs">
            <button
              onClick={this.props.openSettings}
              className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
            >
              <FiSettings className="text-gray-400 text-xl mx-auto ml-3 " />
            </button>

            <button
              onClick={this.props.openSettings}
              className=" fade-in mb-4 mt-4 transition duration-100  hover:opacity-80"
            >
              <FiMoreHorizontal className="text-gray-400 text-2xl mx-auto mr-3 " />
            </button>
          </div>

          <div className="transition-all lg:hidden z-50 duration-300 fixed bg-gray-800 h-16 w-screen bottom-0 left-0 flex flex-row items-center  shadow-lg">
            {/* <button onClick={this.handleNavToggle} className=" group transition duration-200 hover:bg-gray-700 bg-gray-900 w-full shadow-md  h-20 mx-auto">
            {navToggleButton}
            </button> */}

            <Routing
              navItems={navItems}
              navText={navText}
              userType={this.props.userData.UserType}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
