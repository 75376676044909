import { Component } from "react";
import { FaUserTie } from "react-icons/fa";
import { RiUser3Fill } from "react-icons/ri";
import { formatFullDate, formatStringDate } from "../utils/DateFormat";
import { formatAmountForDisplay } from "../utils/DollarFormat";
import {
  fetchGetJSON,
  fetchRequestJSON,
  regGet,
  regRequest,
} from "../api-requests/apiCallHelper";
import { Constants } from "../api-requests/apiLinkConstants";
import store from "../redux/store";
import tenantReducer from "../redux/tenantReducer";
import { GetAppStage } from "../api-requests/api-gateway";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { getCurrentUser } from "aws-amplify/auth";
import Loading from "../components/LoadingWidget";
import dayjs from "../utils/Dayjs";

// Takes Booking ID Prop and pull all info

interface Props {}

interface InvoiceLine {
  id: number;
  created_at: string;
  updated_at: string;
  entry: string;
  cost: number;
  invoice_id: number;
  line_type: string;
  start_date: string;
  end_date: string;
}

interface QuickViewModalState {
  corporate: boolean;
  booking_id: number;
  corporate_id: number;
  corporate_name: string;
  corporate_address: string;
  corporate_postal_code: string;
  corporate_province_state: string;
  corporate_country: string;
  corporate_city: string;
  tenant_name: string;
  tenant_email: string;
  check_in: string;
  check_out: string;
  suite: string;
  editToggle: boolean;
  paid: boolean;
  verified: boolean;
  tenant_name_display: string;
  paid_date: string;

  invoice_id: number;
  invoice_type: string;
  rent_invoice: boolean;
  confirmed_by: string;
  marked_by: string;
  due_date: any;
  // end_date: any;
  invoice_total: number;
  flagged: boolean;

  toggleTransfer: boolean;
  toggleRate: boolean;
  toggleCancel: boolean;
  bamboraURL: string;
  line_items: InvoiceLine[];

  payment_method: string;

  isHidden: boolean;

  isNetSuite: boolean;
  isLoading: boolean;

  isPrinting: boolean; //Used to hide the scroll notification
  invoiceInfo: any;
}

class ClientInvoice extends Component<Props, QuickViewModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      corporate: false,
      booking_id: -1,
      corporate_id: -1,
      corporate_name: "",
      corporate_address: "",
      corporate_postal_code: "",
      corporate_province_state: "",
      corporate_country: "",
      corporate_city: "",
      tenant_name: "",
      tenant_email: "",
      check_in: "",
      check_out: "",
      suite: "",
      editToggle: false,
      paid: false,
      verified: false,
      tenant_name_display: "",
      paid_date: "",
      payment_method: "TBD",
      marked_by: "",
      confirmed_by: "",

      invoice_id: -1,
      invoice_type: "",
      // issue_date: "",
      rent_invoice: false,
      due_date: "",
      // end_date: "",
      invoice_total: 0,
      flagged: false,

      toggleTransfer: false,
      toggleRate: false,
      toggleCancel: false,
      bamboraURL: "",
      line_items: [
        {
          id: -1,
          created_at: "",
          updated_at: "",
          entry: "",
          cost: 0,
          invoice_id: -1,
          line_type: "",
          start_date: "",
          end_date: "",
        },
      ],

      isHidden: false,
      isNetSuite: false,

      isLoading: true,
      isPrinting: false,
      invoiceInfo: null,
    };
  }

  verifiedUser = async () => {
    try {
      // await Auth.currentAuthenticatedUser();
      await getCurrentUser();
      return true;
    } catch {
      return false;
    }
  };

  loggedInGetData = async () => {
    let invoiceData: any;
    let invoicelines: InvoiceLine[];
    let bookingData: any;
    let curTenant: any;
    let corpData: any;

    // console.log("SIGNATURE")
    // console.log(new URLSearchParams(
    //   window.location.href
    // ).get("signature"));

    // console.log(new URLSearchParams(window.location.search).get("signature"));

    // Public access with signature verification
    if (new URLSearchParams(window.location.search).get("signature")) {
      const id = new URLSearchParams(window.location.search).get("id");
      const publicData = await regGet(
        `${Constants.API_PATH_INVOICE_SIGNATURE_CHECK}${new URLSearchParams(
          window.location.search
        ).get("id")}?signature=${new URLSearchParams(
          window.location.search
        ).get("signature")}`
      );

      if (id.toString().includes("NS")) {
        this.setState({
          isNetSuite: true,
        });
      }
      // console.log(publicData)
      if (publicData.statusCode || !publicData.invoice) {
        // console.log("this is getting hit");
        return;
      }
      this.setState({
        verified: true,
        isLoading: false,
      });

      this.setState({
        bamboraURL: publicData.payment_url,
        invoiceInfo: publicData.invoice,
      });
      invoiceData = publicData.invoice;
      bookingData = publicData?.booking;
      curTenant = publicData.tenant;
      invoicelines = publicData.invoice_lines;
      if (bookingData?.corporate_id) {
        corpData = publicData.corporate;
      }

      // if (bookingData) {
      //   if(bookingData.corporate_id) {
      //     let this_corporate = await fetchGetJSON(Constants.API_PATH_CORP_GET + bookingData.corporate_id);
      //     if(this_corporate.data) {
      //       corpData = this_corporate.data;
      //     }
      //   }
      // }
    }
    // Private access
    else {
      // So API routes don't get called if url typed in manually while logged out
      if (!(await this.verifiedUser())) {
        return;
      }
      this.setState({
        verified: true,
      });
      const allInvoices = await fetchGetJSON(
        Constants.API_PATH_INVOICE_GET_ALL
      );
      invoiceData = allInvoices.data.find(
        (invoiceItem: any) =>
          invoiceItem.id ===
          Number(
            new URLSearchParams(window.location.search).get("trnOrderNumber")
          )
      );
      let allInvoiceLines = await fetchGetJSON(
        `${Constants.API_PATH_INVOICE_LINE_GET_ALL}${invoiceData.id}`
      );
      invoicelines = allInvoiceLines.data;
      // Get this invoices booking info
      if (invoiceData) {
        const allBookings = await fetchGetJSON(
          Constants.API_PATH_BOOKING_GET_ALL
        );
        bookingData = allBookings.data.find(
          (bookingItem: any) => bookingItem.id === invoiceData.booking_id
        );
      } else {
        return;
      }

      // Get this invoices tenant info
      if (bookingData) {
        const tenants = await fetchGetJSON(Constants.API_PATH_TENANT_GET_ALL);
        curTenant = tenants.data.find(
          (tenantItem: any) => tenantItem.id === bookingData.tenant_id
        );
      }

      // Get this invoices corporate info
      if (bookingData) {
        const allCorps = await fetchGetJSON(Constants.API_PATH_CORP_GET_ALL);
        corpData = allCorps.data.find(
          (corpItem: any) => corpItem.id === bookingData.corporate_id
        );
      }
    }

    let end_date = "";
    if (invoiceData.end) {
      end_date = invoiceData.end;
    }
    if (bookingData) {
      this.setState({
        booking_id: bookingData.id,
        check_in: bookingData.check_in,
        check_out: bookingData.check_out,
        paid_date: bookingData.date_paid,
      });
    }

    if (curTenant) {
      this.setState({
        tenant_name: `${curTenant?.first_name}+${curTenant?.last_name}`,
        tenant_email: curTenant?.email,
        tenant_name_display: `${curTenant?.first_name} ${
          curTenant?.last_name ?? ""
        }`,
      });
    }

    //get invoice total cost
    let invoiceAmount = 0;
    for (const i in invoicelines) {
      invoiceAmount += invoicelines[i].cost;
    }
    // console.log(invoiceData)
    this.setState({
      paid: invoiceData.paid,
      marked_by: invoiceData.marked_by,
      confirmed_by: invoiceData.confirmed_by,
      invoice_id: invoiceData.id,

      // issue_date: invoiceData.created_at.split('T')[0],
      rent_invoice: invoiceData.rent,
      due_date: this.state.isNetSuite
        ? invoiceData.due
        : invoiceData.due.split("T")[0],
      // end_date: end_date.split('T')[0],
      invoice_total: this.state.isNetSuite ? invoiceData.cost : invoiceAmount,
      invoice_type: invoiceData.invoice_types,
      line_items: invoicelines,
      payment_method: invoiceData.payment_method_invoice,
    });

    if (corpData) {
      this.setState({
        corporate: true,
        corporate_id: corpData.id,
        corporate_name: corpData.corporate_name,
        corporate_address: corpData.address,
        corporate_city: corpData.city,
        corporate_postal_code: corpData.postal_code,
        corporate_province_state: corpData.province_state,
        corporate_country: corpData.country,
      });
    }

    if (invoiceData.invoice_types === "Rent") {
      this.setState({
        invoice_type: "Rent",
      });
    }
  };

  componentDidMount = async () => {
    this.loggedInGetData();
    this.startHideTimer();
  };

  startHideTimer = () => {
    setTimeout(() => {
      this.setState({ isHidden: true });
    }, 300000);
  };

  // Fill out info upon redirect from Bambora transaction completion
  redirectedGetData = async () => {
    const confirmInvoice = await regGet(
      `${Constants.API_PATH_PAYMENT_CONFIRM}?${
        window.location.href.split("?")[1]
      }`
    );

    if (confirmInvoice && confirmInvoice.statusCode !== "500") {
      let invoiceEditBody = {
        payment_method_invoice: "Online Credit",
        date_paid: `${new URLSearchParams(window.location.search).get(
          "trnDate"
        )}`,
      };
      await fetchRequestJSON(
        "PUT",
        `${Constants.API_PATH_INVOICE_EDIT}${this.state.invoice_id}`,
        invoiceEditBody
      );

      this.setState({
        verified: true,
        paid: true,
        paid_date: `${new URLSearchParams(window.location.search).get(
          "trnDate"
        )} PDT`,
        payment_method: "Online Credit",
      });
    }
  };

  handleEditBarToggle = () => {
    let toggle = this.state.editToggle;
    if (toggle === true) {
      this.setState({ editToggle: false });
    } else {
      this.setState({ editToggle: true });
    }
  };

  handleInputChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      returnObj[this.target.id] = this.target.value;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleDateChange = (event: any) => {
    event.preventDefault();
    let stateObject = function (this: typeof event) {
      let returnObj: any = {};
      let dateState;
      if (this.target.value === "") {
        dateState = "";
      } else {
        let toDate = Date.parse(this.target.value);
        dateState = new Date(toDate);
        dateState.setTime(
          dateState.getTime() + dateState.getTimezoneOffset() * 60 * 1000
        );
      }
      returnObj[this.target.id] = dateState;
      return returnObj;
    }.bind(event)();
    this.setState(stateObject);
  };

  handleToggleChange = (id: any, state: boolean) => {
    let stateObject = (function (this: typeof id) {
      let returnObj: any = {};
      if (state === true) {
        returnObj[id] = false;
        return returnObj;
      } else {
        returnObj[id] = true;
        return returnObj;
      }
    })();
    this.setState(stateObject);
  };

  handleClick = async () => {
    const paymentBody = {
      billing: {
        name: this.state.tenant_name,
        email: this.state.tenant_email,
      },
      email_link: false,
    };

    let bamboraUrl: string;
    window.location.href = this.state.bamboraURL;
  };

  downloadPdfDocument = () => {
    // Hide the scroll display
    this.setState({ isPrinting: true }, () => {
      // Add a timeout to ensure the DOM updates
      setTimeout(() => {
        const AppStage = process.env.REACT_APP_AWS_API_GATEWAY_LINK.split("/")
          .pop()
          .toLowerCase();
        const input = document.getElementById("clientInvoice");
        html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF();
          pdf.addImage(imgData, "JPEG", 0, 0, 218, 0, "", "FAST", 0);
          pdf.save(`${AppStage} Invoice #${this.state.invoice_id}.pdf`);

          // Re-enable the scroll display
          this.setState({ isPrinting: false });
        });
      }, 300); // Adjust delay as necessary (300ms is often sufficient)
    });
  };

  render() {
    let corporateIcon = <></>;

    let corporateData = <></>;

    if (this.state.corporate !== false) {
      corporateData = (
        <div className="flex flex-col mt-8">
          <span className="mr-5 text-sm text-gray-400">Corporate Data</span>
          <span className=" text-lg font-bold text-gray-600 mt-1">
            {this.state.corporate_name}
          </span>

          <span className=" text-xs text-gray-300 mt-1">
            {this.state.corporate_address}
          </span>
          <span className=" text-xs text-gray-300 mt-1">
            {this.state.corporate_city} {this.state.corporate_province_state}
          </span>
          <span className=" text-xs text-gray-300 mt-1">
            {this.state.corporate_country}
          </span>
        </div>
      );
      corporateIcon = <FaUserTie className="text-4xl text-gray-500" />;
    } else {
      corporateIcon = <RiUser3Fill className="text-3xl text-gray-500" />;
    }

    // let editFocus = "opacity-100"
    let bgFocus;
    let paidStamp = <></>;
    let editFocus = "opacity-100";

    if (this.state.editToggle === true) {
      editFocus = "opacity-100";
      bgFocus = (
        <div
          onClick={this.handleEditBarToggle}
          className=" fade-bg w-full h-screen bg-black z-40  fixed"
        ></div>
      );
    }
    let payButton = (
      <button
        onClick={() => this.handleClick()}
        className={
          "no-print mt-12 align-center px-8 py-2 bg-green-500 hover:bg-green-400 text-white"
        }
        disabled={this.state.paid}
      >
        Pay
      </button>
    );
    if (this.state.paid === true) {
      payButton = <></>;
      paidStamp = (
        <div className="text-green-500 shadow-sm w-60 border-4 rounded-sm border-green-500 font-bold px-4 pb-1">
          <span className="flex flex-row items-center">
            This Invoice Has Been Paid
          </span>
        </div>
      );
    } else if (this.state.marked_by && !this.state.confirmed_by) {
      payButton = <></>;
      paidStamp = (
        <div className="text-yellow-400 shadow-sm self-end border-4  rounded-sm  border-yellow-400 font-bold px-4 pb-1">
          <span className="flex flex-row items-center ">
            This Invoice is Pending Approval
          </span>
        </div>
      );
    }

    let topLineInfo = (
      <span className="text-4xl font-extrabold text-gray-600 ">
        {" "}
        <span className="text-gray-300">Invoice #: </span>
        {this.state.invoice_id}
      </span>
    );

    if (this.state.verified === false) {
      payButton = <></>;
      topLineInfo = (
        <span className="text-4xl font-extrabold text-gray-600 ">
          {" "}
          <span className="text-gray-300">
            Permissions restricted for this invoice
          </span>
        </span>
      );
    }

    let dueDate;
    if (this.state.due_date == "") {
      dueDate = "-";
    } else if (this.state.isNetSuite) {
      dueDate = this.state.due_date;
    } else {
      dueDate = formatStringDate(this.state.due_date);
    }

    let dateDisplay = (
      <div className="flex flex-row">
        <div className="flex flex-col mr-6">
          <span className="flex flex-col mr-5 text-sm text-gray-400">
            Invoice Date
          </span>
          <span className=" mr-5 font-bold text-md text-gray-700">
            {!this.state.isNetSuite
              ? formatStringDate(this.state.due_date)
              : dayjs(this.state.due_date).format("MMMM Do YYYY")}
          </span>
          <span className="flex flex-col mr-5 text-sm text-gray-400">
            Due Upon Receipt
          </span>
        </div>
      </div>
    );
    if (this.state.paid_date !== "" && this.state.paid_date) {
      dateDisplay = (
        <div className="flex flex-row">
          <div className="flex flex-col">
            <span className=" mr-5 text-sm text-gray-400">Paid Date</span>
            <span className=" mr-5 font-bold text-md text-gray-700">
              {this.state.paid_date}
            </span>
          </div>
        </div>
      );
    }

    let backgrounds = [];
    for (let i = 0; i < 12; i++) {
      backgrounds[i] = "https://wallpaper.dog/large/20355534.jpg";
    }

    return (
      <div
        id="outsideDiv"
        className={
          "fade-in fixed grid grid-cols-1 w-full h-dvh bg-gray-900 z-50"
        }
      >
        <img
          className=" fade-in object-cover bg-gray-100 absolute inset-0  w-full h-full"
          src={backgrounds[0]}
          style={{ zIndex: -1 }}
        />
        <div className="fade-in font-display bg-white md:my-10 mx-auto w-full overflow-y-auto shadow-lg md:rounded-lg h-dvh md:h-auto max-w-3xl scroll-hide z-60 flex items-center justify-center">
          <div
            className="w-full h-auto max-h-full pt-3 pb-20 px-5 sm:pt-6 sm:px-8"
            id="clientInvoice"
          >
            {this.state.isLoading ? (
              <div className="flex flex-col items-center justify-center h-full">
                <h1 className="mb-10 text-xl font-bold text-gray-700">
                  Loading in Invoice
                </h1>
                <Loading size="3x-large" type="big-spiral" />
              </div>
            ) : (
              <>
                <div className="w-full flex flex-row font-sofia justify-between">
                  <div className=" flex flex-col ">
                    {topLineInfo}
                    {!this.state.isPrinting && (
                      <div className=" flex sm:hidden flex-col h-auto ">
                        {/* <img className="w-24" src={process.env.REACT_APP_SVSRELO_COMPANY_LOGO}/> */}
                        <span className="text-lg font-regular text-gray-400 uppercase">
                          {/* {GetAppStage().name1}{" "} */}
                          <div className="mt-4 flex flex-col items-center">
                            <span className="text-xs text-green-400">
                              Scroll down to {this.state.paid ? "print" : "pay"}
                            </span>
                            <div className="animate-bounce mt-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 text-green-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 3a1 1 0 011 1v8.586l2.293-2.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L9 12.586V4a1 1 0 011-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                        </span>

                        {/* {paidStamp} */}
                      </div>
                    )}

                    <span className="text-lg font-base text-gray-400 uppercase mb-2 mt-6">
                      {this.state.invoice_type} Invoice
                    </span>
                    {dateDisplay}
                    <div className="flex flex-col mt-8">
                      <span className="mr-5 text-sm text-gray-400">
                        Invoice For
                      </span>
                      <span className=" text-lg font-bold text-gray-600 mt-1">
                        {this.state.tenant_name_display}
                      </span>

                      <span className=" text-xs text-gray-500 mt-1">
                        HST No.: {process.env.REACT_APP_HST_NO}
                      </span>
                    </div>
                    <span className="mt-4">{paidStamp}</span>
                    {corporateData}
                  </div>

                  <div className=" hidden sm:flex flex-col h-auto justify-between ">
                    {/* <img className="w-24" src={process.env.REACT_APP_SVSRELO_COMPANY_LOGO}/> */}
                    <span className="text-lg font-regular text-gray-400 uppercase self-end ">
                      {/* {GetAppStage().name1}{" "} */}
                      {process.env.REACT_APP_COMPANY_NAME}
                      {!this.state.isPrinting && (
                        <div className="mt-4 flex flex-col items-center">
                          <span className="text-xs text-green-400">
                            Scroll down to {this.state.paid ? "print" : "pay"}
                          </span>
                          <div className="animate-bounce mt-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 text-green-400"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 3a1 1 0 011 1v8.586l2.293-2.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L9 12.586V4a1 1 0 011-1z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </span>

                    {/* {paidStamp} */}
                  </div>
                </div>

                <table
                  style={{ borderSpacing: "0" }}
                  className="border-separate rounded-sm mt-6 relative w-full table-auto bg-white     "
                >
                  <thead>
                    <tr>
                      <th className="py-3 pl-5 font-thin border-b border-gray-400 text-left">
                        {this.state.isNetSuite ? "Item Description" : "Item"}
                      </th>
                      <th className="py-3 font-thin border-b border-gray-400 text-left">
                        {this.state.isNetSuite ? "Rate" : "Payment Method"}
                      </th>
                      {this.state.isNetSuite && (
                        <th className="py-3 font-thin border-b border-gray-400 text-left pl-3">
                          Quantity
                        </th>
                      )}
                      <th className="py-3 font-thin border-b border-gray-400">
                        Price
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y">
                    <PaymentsToApprove
                      length={this.state.line_items.length}
                      items={this.state.line_items}
                      payment_method={this.state.payment_method}
                      isNetSuite={this.state.isNetSuite}
                      invoice={this.state.invoiceInfo}
                    />
                  </tbody>
                </table>

                <div className="w-full flex flex-row justify-between py-5 ">
                  <div className="flex flex-col text-sm">
                    <span className="text-base mb-1">
                      Payment Types Accepted:{" "}
                    </span>
                    <span className=" text-gray-500">
                      Credit Card, Debit, Certified Cheque, Wire
                    </span>
                    <span className="text-xs text-gray-400">
                      All Amounts In CAD
                    </span>
                  </div>
                  {!this.state.isNetSuite && (
                    <span className="text-right text-2xl font-bold text-gray-600">
                      <span className="font-thin text-lg">Total:</span>{" "}
                      {formatAmountForDisplay(
                        this.state.invoice_total,
                        "CAD",
                        false
                      )}{" "}
                    </span>
                  )}
                </div>
                <div className="flex flex-col text-xs text-gray-400 justify-center items-start mt-5">
                  {/* <span className="text-gray-700">{GetAppStage().name1}</span> */}
                  <span className="text-gray-700">
                    {process.env.REACT_APP_COMPANY_NAME}
                  </span>
                  <span>{process.env.REACT_APP_COMPANY_ADDRESS}</span>
                  <span>{process.env.REACT_APP_ADDITIONAL_ADDRESS}</span>
                  <span className="text-green-500">
                    {process.env.REACT_APP_INFO_EMAIL}
                  </span>
                </div>
                <div
                  className={`w-full grid grid-cols-2 gap-5 px-6 pb-10 ${
                    this.state.isHidden ? "hidden" : ""
                  }`}
                >
                  {payButton}
                  <button
                    onClick={() => this.downloadPdfDocument()}
                    className=" no-print mt-12 align-center px-8 py-2 bg-gray-100 hover:bg-gray-200 border border-gray-300 text-gray-700"
                  >
                    Print
                  </button>
                </div>
                {this.state.isHidden && (
                  <div className={`w-full grid grid-cols-2 gap-5 px-6 pb-10`}>
                    <span className="text-red-300 mt-10">
                      Payment Link has expired, please refresh the page to
                      access the link again.
                    </span>
                    <button
                      onClick={() => this.downloadPdfDocument()}
                      className=" no-print mt-12 align-center px-8 py-2 bg-gray-100 hover:bg-gray-200 border border-gray-300 text-gray-700"
                    >
                      Print
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ClientInvoice;

export function PaymentsToApprove(props: any) {
  const { items, isNetSuite, payment_method, invoice } = props;

  // Ensure invoice details are safely handled
  const invoiceId = invoice?.id ?? "Unknown";
  const startDate = invoice?.start_date ?? "Unknown Start Date";
  const endDate = invoice?.end_date ?? "Unknown End Date";

  if (isNetSuite) {
    // Combine GST and PST as HST
    const taxes = items?.filter((item: any) =>
      ["GST", "PST"].includes(item.description)
    );
    const hst = taxes.reduce(
      (sum: number, taxItem: any) => sum + parseFloat(taxItem.amount),
      0
    );

    // Separate core items and adjustments
    const coreItems = items.filter(
      (item: any) =>
        !["GST", "PST"].includes(item.description) &&
        !item?.description?.toLowerCase().includes("adjustment")
    );
    const adjustments = items.filter((item: any) =>
      item?.description?.toLowerCase().includes("adjustment")
    );
    console.log(adjustments);
    // Reformat adjustment descriptions
    const formattedAdjustments = adjustments.map((item: any) => ({
      ...item,
      description:
        item.description ?? `Rent Adjustment (Invoice #${invoiceId})`,
    }));

    // Calculate subtotal dynamically
    const subtotal = coreItems.reduce(
      (sum: any, item: any) => sum + parseFloat(item.amount),
      0
    );

    // Calculate total adjustments
    const totalAdjustments = formattedAdjustments.reduce(
      (sum: number, item: any) => sum + parseFloat(item.amount),
      0
    );

    // Calculate total dynamically
    const total = subtotal + hst + totalAdjustments;

    // Render rows for core items
    const renderCoreItems = coreItems.map((item: any, i: any) => (
      <tr
        key={i}
        className={`${
          i % 2 === 0 ? "bg-gray-50" : "bg-white"
        } h-12 border-b border-gray-400`}
      >
        <td className="py-4 pl-5 text-gray-700 font-thin text-sm">
          {item.description}
        </td>
        <td className="py-3 font-thin text-sm text-right">
          {formatAmountForDisplay(item.rate, "CAD", false)}
        </td>
        <td className="py-3 font-thin text-sm pl-3">{item.quantity}</td>
        <td className="py-3 text-green-500 font-thin text-right px-4">
          {formatAmountForDisplay(parseFloat(item.amount), "CAD", false)}
        </td>
      </tr>
    ));

    // Render rows for adjustments
    const renderAdjustmentsSection = formattedAdjustments.length > 0 && (
      <>
        {/* Credit Memo Header with Line Underneath */}
        <tr
          className="bg-white border-b-4 border-gray-300" //Not sure why the border isn't working but we can leave it alone for now
        >
          <td colSpan={4} className="py-2 pl-5 text-left text-l underline">
            Credit Memo (Refund or Adjustment)
          </td>
        </tr>

        {/* Render Each Credit Memo Line */}
        {formattedAdjustments.map((item: any, i: any) => (
          <tr key={`adjustment-${i}`} className="bg-white">
            <td
              colSpan={3}
              className="py-2 pl-5 text-right text-gray-500 font-thin"
            >
              {item.description}
            </td>
            <td className="py-2 text-right text-red-500 font-thin pr-4">
              {formatAmountForDisplay(parseFloat(item.amount), "CAD", false)}
            </td>
          </tr>
        ))}
      </>
    );

    // Render rows for subtotal, HST, adjustments, and total
    const renderSummaryRows = (
      <>
        <tr className="bg-white">
          <td
            colSpan={3}
            className="py-2 pl-5 text-right text-gray-500 font-thin"
          >
            Subtotal
          </td>
          <td className="py-2 text-right text-green-500 font-thin pr-4">
            {formatAmountForDisplay(subtotal, "CAD", false)}
          </td>
        </tr>
        <tr className="bg-white">
          <td
            colSpan={3}
            className="py-2 pl-5 text-right text-gray-500 font-thin"
          >
            HST (13%)
          </td>
          <td className="py-2 text-right text-green-500 font-thin pr-4">
            {formatAmountForDisplay(hst, "CAD", false)}
          </td>
        </tr>
        {renderAdjustmentsSection}
        <tr className="bg-white">
          <td
            colSpan={3}
            className="py-3 pl-5 text-right text-gray-700 font-bold text-2xl"
          >
            Total
          </td>
          <td className="py-3 text-right text-green-500 font-bold pr-4 text-2xl">
            {formatAmountForDisplay(total, "CAD", false)}
          </td>
        </tr>
      </>
    );

    return (
      <>
        {renderCoreItems}
        {renderSummaryRows}
      </>
    );
  }

  // Default View (Non-NetSuite)
  const rows = [];
  let altBg = "";

  for (let i = 0; i < props.length; i++) {
    const lineItem = props.items[i];
    altBg = i % 2 === 0 ? "bg-gray-50" : "bg-white";

    rows.push(
      <tr key={i} className={`${altBg} h-12 border-b border-gray-400`}>
        <td className="py-4 pl-5 text-gray-700 font-thin text-sm">
          {lineItem.entry.split(">")[0]}
        </td>
        <td className="py-3 font-thin text-sm">{payment_method}</td>
        <td className="py-3 text-green-500 font-thin text-right px-4">
          {formatAmountForDisplay(lineItem.cost, "CAD", false)}
        </td>
      </tr>
    );
  }

  return <>{rows}</>;
}
