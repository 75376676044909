import React, { useState, useEffect } from "react";
import { createTheme, TextField } from "@mui/material";
import { ThemeProvider, PaletteColorOptions } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import { Constants } from "../../../api-requests/apiLinkConstants";
import {
  fetchGetJSON,
  fetchRequestJSON,
  regGet,
  regRequest,
} from "../../../api-requests/apiCallHelper";
import { useParams } from "react-router";
import { useNotification } from "../../Notifications/NotificationProvider";
import Logo from "../../../client-facing/img/SKYVIEWSUITESDark.png";

declare module "@mui/material/styles" {
  interface CustomPalette {
    forestGreen: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    forestGreen: true;
  }
}
declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides {
    forestGreen: true;
  }
}
declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    forestGreen: true;
  }
}
const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: any) =>
  augmentColor({ color: { main: mainColor } });
const theme = createTheme({
  palette: {
    forestGreen: createColor("#03937F"),
  },
});

function CheckOutForm(this: any, props: any) {
  const { bookingId } = useParams<{ bookingId: string }>();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [count, setCount] = useState(1);
  const [booking, setBooking] = useState<any>();
  const [tenant, setTenant] = useState<any>();
  const [unit, setUnit] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);

  const validate = () => {
    let isDateFilled = date.length;
    let isTimeFilled = time.length;
    return { isDateFilled, isTimeFilled };
  };

  let id = count;

  const dispatch = useNotification();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const sig = new URLSearchParams(window.location.search).get("signature");
    //Need to call our extension create and then route

    // Add Check out logic here VVVV
    const res = await regRequest(
      "PUT",
      `${Constants.API_PATH_BOOKING_HARD_CHECK_OUT}/${bookingId}?signature=${sig}`,
      {
        id: bookingId,
        notes: "Hard CheckOut Set",
      }
    );

    if (res.status === "Success") {
      HandleError(
        "Success",
        `Form Succesfully Submitted`,
        "Thank you very much for confirming your checkout with us today!"
      );
    } else {
      HandleError("Error", `Error Confirming Check Out`, `${res.status}`);
    }
  };

  function HandleError(type: string, title: string, message: string) {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  }

  useEffect(() => {
    let sig = new URLSearchParams(window.location.search).get("signature");
    regGet(
      `${Constants.API_PATH_CHECKOUT_FORM}${bookingId}?signature=${sig}`
    ).then((response) => {
      setBooking(response);
    });
  }, []);

  const CheckOutFields = () => {
    return (
      <div className="mt-4">
        <div className="flex flex-wrap gap-4">
          <TextField
            inputProps={{ style: { padding: 16 } }}
            InputLabelProps={{ shrink: true }}
            id="date"
            required
            fullWidth
            label="Date"
            value={date}
            type="date"
            color="forestGreen"
            onChange={(e) => setDate(e.target.value)}
          />

          <TextField
            inputProps={{ style: { padding: 16 } }}
            InputLabelProps={{ shrink: true }}
            id="time"
            required
            fullWidth
            label="Time"
            value={time}
            type="time"
            color="forestGreen"
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
      </div>
    );
  };

  if (booking) {
    return (
      <ThemeProvider theme={theme}>
        <div className="flex flex-col h-screen items-center justify-center p-4">
          <div className="flex w-2/3 md:w-1/3 sm:w-1/2 md:mb-8 sm:mb-4 mb-4">
            <img src={Logo} alt="Sky View Suites Logo" />
          </div>
          <form className="w-full md:w-1/2 sm:w-1/2 bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-center text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-700 font-black font-display mb-2 sm:mb-3 md:mb-4 lg:mb-5 xl:mb-6">
              Confirm Check-Out
            </h2>
            <div className="mb-4" />
            <div className="flex flex-wrap md:flex-nowrap gap-4 mb-4">
              <div className="w-full md:w-1/2">
                <TextField
                  inputProps={{
                    style: {
                      padding: 16,
                    },
                  }}
                  disabled
                  fullWidth
                  id="outlined-read-only-input"
                  label="First Name"
                  value={
                    booking.tenant?.first_name
                      ? booking.tenant.first_name
                      : "N/A"
                  }
                />
              </div>
              <div className="w-full md:w-1/2">
                <TextField
                  inputProps={{
                    style: {
                      padding: 16,
                    },
                  }}
                  disabled
                  fullWidth
                  id="outlined-read-only-input"
                  label="Last Name"
                  value={
                    booking.tenant?.last_name ? booking.tenant.last_name : "N/A"
                  }
                />
              </div>
            </div>
            <TextField
              inputProps={{
                style: {
                  padding: 16,
                },
              }}
              disabled
              fullWidth
              id="outlined-read-only-input"
              label="Unit Name"
              value={booking.unit?.suite_name ? booking.unit.suite_name : "N/A"}
              className="mb-4"
            />

            <TextField
              inputProps={{
                style: {
                  padding: 16,
                },
              }}
              disabled
              fullWidth
              id="outlined-read-only-input"
              label="Check-Out"
              value={booking?.check_out ? booking?.check_out : "N/A"}
              className="mb-4"
            />

            <div className="mb-4">
              <FormControlLabel
                control={
                  <Checkbox
                    color="forestGreen"
                    onChange={() => setIsChecked(!isChecked)}
                  />
                }
                label="Early Check-Out"
                className="text-gray-700 font-black font-display"
              />
              {isChecked && <CheckOutFields />}
            </div>

            <TextField
              inputProps={{
                style: {
                  padding: 16,
                  borderColor: "transparent",
                  outline: "none",
                },
              }}
              id="input"
              fullWidth
              label="Notes"
              multiline={false}
              color="forestGreen"
              className="mb-4"
            />

            <div className="flex justify-end">
              <Button
                disabled={!validate()}
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "forestgreen",
                  color: "white",
                  borderRadius: "10%",
                  padding: "10px 20px",
                  fontFamily: "font-display",
                  fontWeight: "black",
                }}
                className="text-white-700 font-black font-display focus:outline-none bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 me-2 mb-2 mt-4"
              >
                Submit
              </Button>
            </div>
            <aside className="spacerY" />
          </form>
        </div>
      </ThemeProvider>
    );
  } else {
    return <div>nothing</div>;
  }
}

export default CheckOutForm;
