import { useState } from "react";
import { Modal } from "@mui/material";
import {
  fetchGetJSON,
  fetchRequestJSON,
} from "../../api-requests/apiCallHelper";
import { Constants } from "../../api-requests/apiLinkConstants";
import { useNotification } from "../Notifications/NotificationProvider";

interface Props {
  open: boolean;
  closeModal: any;
  // submitFlag: any;
  flagged: any;
  bookingId: string;
  flag_reason: any;
}

function FlagModal(props: Props) {
  const [flagReason, setFlagReason] = useState("N/A");
  const dispatch = useNotification();

  function onFlagReasonChange(event: any) {
    event.preventDefault();
    console.log(event.target.value);
    setFlagReason(event.target.value);
  }

  function HandleError(type: string, title: string, message: string) {
    dispatch({
      type: type.toUpperCase(),
      title: title,
      message: message,
    });
  }

  async function submitFlag() {
    // props.flagBooking();
    const booking = await fetchGetJSON(
      `${Constants.API_PATH_BOOKING_GET}${props.bookingId}`
    );

    if (booking.status === "Success") {
      const editBooking = await fetchRequestJSON(
        "PUT",
        Constants.API_PATH_BOOKING_EDIT,
        {
          id: props.bookingId,
          fields: {
            flagged: !booking.data[0].flagged,
            flag_reason: flagReason,
          },
        }
      );
      console.log(editBooking);
      if (editBooking.status === "Success") {
        props.flagged();
        props.flag_reason(flagReason);
      } else {
        HandleError(
          "Error",
          "Flagging booking failed",
          `${editBooking.status}`
        );
      }
    } else {
      HandleError(
        "Error",
        "Booking ID does not exist",
        "This booking cannot be flagged"
      );
    }
    props.closeModal();
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.open}
      onClose={() => props.closeModal()}
    >
      <div className=" appear bg-white shadow-lg rounded-lg h-2/5 w-1/2 border-4  border-gray-600 py-8 px-10 mx-auto flex flex-col align-self-center">
        <span className="text-xl font-bold text-gray-800 text-left pb-4">
          Flagging Booking
        </span>
        <label className="pl-3 mb-5 mx-auto flex flex-col">
          <span className="text-gray-400 text-xs">Flagging Reason</span>
          <select
            id="flagReason"
            onChange={(e) => onFlagReasonChange(e)}
            value={flagReason}
            className="mt-0 py-0.5 block w-full px-1 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
            placeholder=""
          >
            <option defaultValue="None" hidden>
              Choose a reason
            </option>
            <option value="Low Rate">Low Rate</option>
            <option value="Troubled Tenants">Troubled Tenants</option>
            <option value="Expected Check Out Issues">
              Expected Check Out Issues
            </option>
          </select>
        </label>
        <div className="h-24 mx-auto flex flex-col">
          <button
            onClick={() => submitFlag()}
            className="transition  duration-200 w-48 hover:bg-green-400 px-10 py-2 bg-gray-800  rounded-sm text-white  shadow-sm  "
          >
            Submit Flag
          </button>
        </div>
      </div>
    </Modal>
  );
}
export { FlagModal };
